$color: #85c324;
.theme-preloader>span {
    background-size: contain;
    background-image: url(./images/logo.svg);
}

.theme-preloader {
    background-color: #0f120b !important;
    border-color: #e8e6e3;
}

// .pagination-box>ul {
//     margin: 30px 0 0;
.pagination-box>ul {
    margin: 1px 0 0;
}

.pagination-box>ul>li.current {
    background-color: var(--button);
    ;
    color: var(--button);
    border-radius: 100%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 6px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.pagination-box>ul>li {
    color: var(--button);
    font-size: 12px;
    margin: 0 12px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-family: Mulish, sans-serif;
}

.pagination-box>ul>li>a {
    color: #fff;
    transition: all 0.5s;
}

.pagination-box>ul>li>a>i {
    margin-left: 4px;
    vertical-align: text-bottom;
}

.pagination-box>ul>li>a:hover {
    color: #0c5adb;
}

.bannar-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.bannar-image-small {
    width: 100%;
    min-height: 150px;
    height: 150px;
    max-height: 150px;
    object-fit: cover;
}

.navbar {
    // background: rgba(0, 0, 0, .15);
    background: var(--navbar-grey);
}

.custom-text {
    color: var(--text);
}

.btn-sub1 {
    @extend .btn-main;
    background: var(--button) !important;
    color: var(--text2) !important;
    &:hover {
        box-shadow: 2px 2px 20px 0px var(--button) !important;
    }
}

.btn-small-secondary {
    // @extend .btn-main;
    //background: #ff343f !important;
    width: 100%;
    display: block;
    // width: max-content;
    text-align: center;
    color: #ffffff !important;
    background: var(--button2);
    border-radius: 12px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 300;
    text-decoration: none;
    padding: 5px 5px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 2px 2px 20px 0px var(--button2) !important;
        transition: all 0.3s ease;
    }
    &.inline {
        display: inline-block;
        margin-right: 15px;
        &.white {
            color: $color_more !important;
            background: $white;
        }
    }
}

.btn-small-primary {
    display: block;
    width: 100%;
    text-align: center;
    color: #fff !important;
    background: var(--button);
    border-radius: 12px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 300;
    text-decoration: none;
    padding: 5px 5px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 2px 2px 20px 0px var(--button);
        transition: all 0.3s ease;
    }
    &.inline {
        display: inline-block;
        margin-right: 15px;
        &.white {
            color: $color_more !important;
            background: $white;
        }
    }
}

.table header {
    color: white;
}

.table .row {
    color: grey;
    border-bottom-color: grey;
    border-bottom-style: solid;
    border-width: 1px;
}

.icon-link {
    cursor: pointer;
    color: var(--button);
    margin: 8px;
}

.icon-link-red {
    cursor: pointer;
    color: #f00;
    margin: 8px;
}

.slick-active {
    padding: 9px;
}

.browse-detail-info .browse-detail-timer .browse-time-left {
    padding-left: 10px;
}

.browse-time-left ul {
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
}

.browse-time-left ul>li {
    float: left;
    margin-right: 10px;
    padding-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: #888;
}

.browse-time-left ul>li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.browse-time-left ul>li>span {
    font-size: 26px;
    font-weight: 700;
    display: block;
    font-family: Mulish, sans-serif;
    line-height: normal;
}

.nft__item_price_extend span {
    margin-left: 10px;
    color: #fff !important;
    font-weight: 700;
}

.nft-card-player video {
    object-fit: cover;
}

.div-scrollable {
    overflow-y: scroll;
    max-height: 400px;
}

.select {
    border: 0 none;
    color: var(--text);
    font-size: 16px;
    padding: 2px 10px;
    width: 100%;
    height: 40px;
    // background: $black_soft;
    background: var(--background);
    border: 1px solid var(--checkbox);
    border-radius: 5px;
}


.hhFSEG {
    background-color: rgb(63, 78, 71);
    color: white;
    margin: 0px;
    padding: 3px 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    text-align: center;
    // transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: left top;
    width: 100%;
    a {
        color: white;
        font-weight: 400;
        text-decoration: none !important;
        &:hover {
            color: $color;
            font-weight: 450;
        }
    }
}

.hhFSEG-newmark {
    background-color: rgb(100, 168, 91);
    color: var(--text);
    margin: 0px;
    padding: 0px 0px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10px;
    text-align: center;
    transform: translateX(-50%) translateY(270%) rotate(270deg);
    // transform-origin: center top;
    width: 25%;
    // width: 250px;
}

.hhFSEG-soldout {
    background-color: rgb(219, 89, 41);
    color: var(--text);
    margin: 0px;
    padding: 0px 0px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10px;
    text-align: center;
    transform: translateX(-50%) translateY(270%) rotate(270deg);
    // transform-origin: center top;
    width: 25%;
    // width: 250px;
}